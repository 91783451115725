import I18n from "./core/localization/I18n";
import { UiSizes, UiPositions } from "@flightpath/coreui/dist/enums";
import { PillIndicatorTypes } from "./components/ui/PillIndicator";
import { PillTypes } from "./components/ui/Pill";

export { UiSizes, UiPositions };

export enum CompanySize {
  TINY = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
  SMALL_ENTERPRISE = 5,
  LARGE_ENTERPRISE = 6
}

export enum ImpactCompletionState {
  Complete = 1,
  Incomplete = 2
}

export enum QueryStringParts {
  Location = "location",
  BusinessArea = "businessArea",
  BusinessAreas = "businessAreas",
  WithinMonth = "withinMonth",
  WithinYear = "withinYear",
  GapAnalysis = "ga",
  Value = "value",
  Level = "level",
  Type = "type",
  Owner = "owner",
  ProgressStatus = "progressStatus",
  RagStatus = "ragStatus",
  AssignedTo = "assignedToName",
  CreatedBy = "createdByName",
  ReviewedBy = "reviewedByName",
  ActionStakeholders = "actionStakeholders",
  ProjectStakeholderNames = "projectStakeholderNames",
  AudienceNames = "audienceNames",
  ActionAudiences = "actionAudiences"
}

export enum EntityTypes {
  IMPACT_GROUPS = "impactGroups",
  IMPACTS = "impacts",
  IMPACT = "impact",
  ACTIONS = "actions",
  ACTION = "action",
  PROJECT_STAKEHOLDERS = "projectStakeholders",
  PROJECT_STAKEHOLDER = "projectStakeholder",
  STAKEHOLDERS = "stakeholders",
  AUDIENCES = "audiences",
  AUDIENCE = "audience",
  STAKEHOLDER = "stakeholder",
  IMPACTTYPE = "impactType",
  BENEFITS = "benefits",
  MILESTONES = "milestones",
  PROCESSES = "processes",
  PHASES = "phases",
  PROJECT_JOB_ROLES = "projectJobRoles",
  PROJECT_JOB_ROLES_PROCESS_ROLES = "projectJobRolesProcessRoles"
}

export enum GridTypes {
  STAKEHOLDER_IMPACTS_GRID = "stakeholderImpactGrid",
  IMPACT_STAKEHOLDER_GRID = "impactStakeholderGrid",
  ACTION_IMPACT_GRID = "actionsImpactGrid",
  JOB_ROLES_GRID = "jobRoleGrid",
  PROCESS_ROLES_GRID = "processRoleGrid",
  IMPACT_ACTION_GRID = "impactActionGrid",
  PROCESS_IMPACT_GROUP_GRID = "processImpactGroupGrid",
  PROCESS_REPORTS_LINK_MODAL_GRID = "processReportsLinkModalGrid",
  IMPACTS_REPORTS_LINK_MODAL_GRID = "impactsReportsLinkModalGrid",
  AUDIENCE_MODAL_GRID = "audienceModalGrid"
}

export enum StakeholderGroupType {
  PROJECT,
  PROGRAMME
}

export enum StakeholderType {
  INDIVIDUAL = 1,
  AUDIENCE = 2
}

export enum StakeholderProfilingType {
  Sentiment = 1,
  Receptiveness = 2,
  Commitment = 3
}

export enum RagStatus {
  UNKNOWN = -1,
  RED = 1,
  AMBER = 2,
  GREEN = 3
}

export enum RagStatusString {
  RED = "red",
  AMBER = "amber",
  GREEN = "green",
  UNKNOWN = "gray"
}

export enum ProgressStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  OVERDUE = 4,
  ONHOLD = 5,
  CANCELLED = 6,
  DRAFT = 7
}

export enum ImpactType {
  PEOPLE = 1,
  PROCESS = 2,
  TECHNOLOGY = 3
}

export enum ImpactLevel {
  UNKNOWN = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

export enum ImpactLevelBulkUpdate {
  LOW = 1,
  MEDIUM = 5,
  HIGH = 10
}

export enum MitigationConfidence {
  UNKNOWN = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

export enum GridType {
  ACTIONS = 1,
  IMPACTS = 2
}

export enum AttitudeLevel {
  NEGATIVE = 1,
  NEUTRAL = 2,
  POSITIVE = 3
}

export enum ConcernStatus {
  ACTIVE = 1,
  CLOSED = 2,
  IRRELEVANT = 3
}

export enum LifecycleStatus {
  Active = 0,
  Archived = 1,
  Deleted = 2
}

//Application Events
export enum AppEventType {
  BeforeStart = 0x01,
  AfterStart = 0x02,
  BeforeCacheStart = 0x03,
  AfterCacheStart = 0x04
}

//Application Errors for test
export enum ErrorCode {
  NullPointerException = 0x00001,
  NetworkDown = 0x00002,
  RequestFailed = 0x00003,
  AccessDenied = 0x00004,
  FormValidationFailed = 0x00005,
  FieldValidationFailed = 0x00006,
  RequestTimeout = 0x00007,
  ResourceUnreachable = 0x00008
}

//Application Log Constants
export enum LogLevel {
  Diagnostics = 0x001,
  Info = 0x002,
  Warning = 0x004,
  Error = 0x008,
  All = 0x0f
}

export enum PermissionScope {
  NOACCESS = 0,
  READER = 100,
  CONTRIBUTOR = 200,
  OWNER = 280,
  ADMIN = 300
}

export enum LocalCookies {
  ORGANISATION_ID = ".FlightPath.client.orgId",
  RECENT_PROJECTS = ".FlightPath.client.recentProjects"
}

export enum ChangeType {
  NEUTRAL = 0,
  NEGATIVE = 1,
  POSITIVE = 2
}

export enum ElementTypes {
  ORGANISATION = 1,
  PROGRAMME = 2,
  PROJECT = 3,
  IMPACT = 4,
  ACTION = 5,
  STAKEHOLDER = 6,
  AUDIENCE = 7,
  IMPACTTYPE = 8,
  NOT_STARTED_ACTION = 9,
  IN_PROGRESS_ACTION = 10,
  UNKNOWN_STAKEHOLDER = 11,
  CONCERNED_STAKEHOLDER = 12,
  NEUTRAL_STAKEHOLDER = 13,
  ADVOCATE_STAKEHOLDER = 14,
  UNKNOWN_AUDIENCE = 15,
  CONCERNED_AUDIENCE = 16,
  NEUTRAL_AUDIENCE = 17,
  ADVOCATE_AUDIENCE = 18,
  RED_RAG_ACTION = 19,
  AMBER_RAG_ACTION = 20,
  GREEN_RAG_ACTION = 21,
  COMPLETE_RAG_ACTION = 22,
  UNKNOWN_RAG_ACTION = 23,
  HIGH_LEVEL_IMPACT = 24,
  MEDIUM_LEVEL_IMPACT = 25,
  LOW_LEVEL_IMPACT = 26,
  UNKNOWN_LEVEL_IMPACT = 27
}

export enum ChangeEventType {
  CREATE = 1,
  UPDATE = 2
}

export enum MilestoneType {
  NOT_SET = 0,
  GO_LIVE = 1,
  COMMS = 2,
  ORG_ANNOUNCEMENT = 3,
  CHANGE_PLAN = 4,
  READINESS = 5,
  TRAINING = 6,
  ENGAGEMENT_ACTIVITY = 7,
  UAT = 8,
  DESIGN_CONFIGURATION = 9,
  DATA_MT = 10,
  HYPERCARE = 11,
  STAGEGATE = 12,
  OTHER = 13
}

export enum TableBadgeTypes {
  GREEN = "green",
  AMBER = "amber",
  RED = "red",
  PURPLE = "purple",
  BLACK = "black",
  BLUE = "blue"
}

export enum SettingType {
  CUSTOM_PROPERTIES_ENABLED = 1,
  VISUALISATIONS_ENABLED = 2,
  TAGS_ENABLED = 3
}

export enum ReviewRange {
  FOUR_TO_SIX_WEEKS = 1,
  TWO_TO_FOUR_WEEKS,
  WITHIN_TWO_WEEKS,
  OVERDUE
}

export enum ImpactLevelDropdownValues {
  High = 9,
  Medium = 5,
  Low = 2,
  Unknown = -1
}

export enum ActionField {
  name = 1,
  description = 2,
  owner = 3,
  startDate = 4,
  endDate = 5,
  progressStatus = 6,
  actionType = 7,
  assignedTo = 8,
  ragStatus = 9,
  projectName = 10,
  programmeName = 11
}

export enum MilestoneField {
  name = 1,
  description = 2,
  deadline = 3,
  milestoneType = 4
}

export enum AudienceField {
  name = 1,
  impact = 2,
  size = 3,
  influence = 4
}

export enum JobRoleField {
  name = 1
}

export enum ProjectJobRoleField {
  name = 1
}

export enum HeatmapMonths {
  JANUARY = "Jan",
  FEBRUARY = "Feb",
  MARCH = "Mar",
  APRIL = "Apr",
  MAY = "May",
  JUNE = "Jun",
  JULY = "Jul",
  AUGUST = "Aug",
  SEPTEMBER = "Sep",
  OCTOBER = "Oct",
  NOVEMBER = "Nov",
  DECEMBER = "Dec"
}

export enum Months {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "Novermber",
  DECEMBER = "December"
}

export enum ImpactField {
  name = 1,
  description = 2,
  owner = 3,
  startDate = 4,
  endDate = 5,
  progressStatus = 6,
  impactType = 7,
  numberImpacted = 8,
  impactLevel = 9,
  mitigationConfidence = 10
}

export enum ProcessField {
  l1_process = 1,
  l2_process = 2,
  l3_process = 3,
  scopeItemId = 4
}

export enum ProjectProcessField {
  PROGRESS_STATUS = 1,
  IS_IN_SCOPE = 2
}

export enum ProjectStakeholderField {
  isKeyStakeholder = 1,
  influence = 2,
  impact = 3,
  commitment = 4,
  receptiveness = 5,
  sentiment = 6
}

export enum AudienceStakeholderProfilingField {
  awareness = 1,
  understanding = 2,
  commitment = 3,
  capability = 4,
  adoption = 5
}

export enum BenefitField {
  name = 1,
  description = 2
}

export enum HeatmapFilters {
  BUSINESSAREA = "business-areas",
  LOCATIONS = "locations",
  PROJECTS = "projects"
}

export enum StakeholderUploadField {
  firstName = 1,
  lastName = 2,
  emailAddress = 3
}

export enum StakeholderField {
  firstName = 1,
  lastName = 2,
  emailAddress = 3,
  businessArea = 4,
  role = 5
}

const profileLabelGenerator = (val: AttitudeLevel | number) => {
  const con = I18n.t("phrases.concerned");
  const neu = I18n.t("phrases.neutral");
  const adv = I18n.t("phrases.advocate");
  let s = ["", con, con, con, con, neu, neu, neu, adv, adv, adv, adv];
  return s[val] || "Unknown";
};
const secondarProfileLabelGenerator = (val: AttitudeLevel | number) => {
  let stakeholderProfilingArray = [
    "",
    I18n.t("phrases.blocker"),
    I18n.t("phrases.critic"),
    I18n.t("phrases.challenger"),
    I18n.t("phrases.generalUnderstanding"),
    I18n.t("phrases.personalUnderstanding"),
    I18n.t("phrases.activeSupporter"),
    I18n.t("phrases.champion"),
    I18n.t("phrases.adopter"),
    I18n.t("phrases.embedSustain"),
    I18n.t("phrases.adaptImprove")
  ];
  return stakeholderProfilingArray[val] || "Unknown";
};
const impactLevelShortToLong = (val: number) => {
  switch (val) {
    case ImpactLevel.HIGH:
      return ImpactLevelDropdownValues.High;
    case ImpactLevel.MEDIUM:
      return ImpactLevelDropdownValues.Medium;
    case ImpactLevel.LOW:
      return ImpactLevelDropdownValues.Low;
    default:
      return ImpactLevelDropdownValues.Unknown;
  }
};
const impactLevelLongToShort = (val: number) => {
  switch (val) {
    case ImpactLevelDropdownValues.High:
      return ImpactLevel.HIGH;
    case ImpactLevelDropdownValues.Medium:
      return ImpactLevel.MEDIUM;
    case ImpactLevelDropdownValues.Low:
      return ImpactLevel.LOW;
    default:
      return ImpactLevel.UNKNOWN;
  }
};

const impactLevelArray = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
const sizeArray = [
  "",
  "Extra Small",
  "Small",
  "Small",
  "Small",
  "Medium",
  "Medium",
  "Medium",
  "Large",
  "Large",
  "Extra Large",
  "Extra Large"
];
const influenceLevelArray = [
  "",
  "Low",
  "Low",
  "Low",
  "Low",
  "Medium",
  "Medium",
  "Medium",
  "High",
  "High",
  "High",
  "High"
];

export function getAllImpactLevelIndexes(value) {
  if (value === "Unknown") return [0, -1];

  var results = [];
  var idx = impactLevelArray.indexOf(value);
  while (idx !== -1) {
    results.push(idx);
    idx = impactLevelArray.indexOf(value, idx + 1);
  }
  return results;
}

export const Translator = {
  SentimentLevel: profileLabelGenerator,
  ReceptivenessLevel: profileLabelGenerator,
  ImpactLevelShortToLong: impactLevelShortToLong,
  ImpactLevelLongToShort: impactLevelLongToShort,
  CommitmentLevel: secondarProfileLabelGenerator,
  MilestoneType: (val: MilestoneType) => {
    let s = [
      "",
      "Go Live",
      "Comms",
      "Org. Announcement",
      "Change Plan",
      "Business Readiness",
      "Training",
      "Engagement Activity",
      "UAT",
      "Design/Configuration",
      "Data",
      "Hypercare",
      "Stage Gate",
      "Other"
    ];
    return s[val];
  },
  MilestoneTypeKey: (val: MilestoneType) => {
    let s = [
      "",
      "goLive",
      "comms",
      "orgAnnouncement",
      "changePlan",
      "businessReadiness",
      "training",
      "engagementActivity",
      "uat",
      "designConfiguration",
      "dataMT",
      "hypercare",
      "stageGate",
      "other"
    ];
    return s[val];
  },
  ImpactType: (val: ImpactType | number) => {
    let s = ["", "People", "Process", "Technology"];
    return s[val];
  },
  ImpactLevel: (val: ImpactLevel | number) => {
    return impactLevelArray[val] || "Unknown";
  },
  Size: (val: any | number) => {
    return sizeArray[val] || "Unknown";
  },
  SizeMapped: () => {
    var s = sizeArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    s.pop();
    return s;
  },
  ImpactLevelMapped: () => {
    var s = impactLevelArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    s.pop();
    return s;
  },
  StakeholderProfilingMapped: () => {
    const con = I18n.t("phrases.concerned");
    const neu = I18n.t("phrases.neutral");
    const adv = I18n.t("phrases.advocate");
    let stakeholderProfilingArray = ["", con, con, con, con, neu, neu, neu, adv, adv, adv];
    var s = stakeholderProfilingArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    return s;
  },
  StakeholderProfilingMappedSecondary: () => {
    const con = I18n.t("phrases.cautious");
    const neu = I18n.t("phrases.neutral");
    const adv = I18n.t("phrases.advocate");
    let stakeholderProfilingArray = ["", con, con, con, con, neu, neu, neu, adv, adv, adv];
    var s = stakeholderProfilingArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    return s;
  },

  StakeholderCommitmentOptions: () => {
    let stakeholderProfilingArray = [
      "",
      I18n.t("phrases.blocker"),
      I18n.t("phrases.critic"),
      I18n.t("phrases.challenger"),
      I18n.t("phrases.generalUnderstanding"),
      I18n.t("phrases.personalUnderstanding"),
      I18n.t("phrases.activeSupporter"),
      I18n.t("phrases.champion"),
      I18n.t("phrases.adopter"),
      I18n.t("phrases.embedSustain"),
      I18n.t("phrases.adaptImprove")
    ];
    var s = stakeholderProfilingArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    return s;
  },
  MitigationConfidence: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  MitigationConfidenceMin: (val: ImpactLevel | number) => {
    let s = ["Unknown", "Low", "Medium", "High"];
    return s[val];
  },
  MitigationConfidenceToPillIndicator: (val: number): PillIndicatorTypes => {
    if (val < 0) return PillIndicatorTypes.GREY;
    if (val > 0 && val <= 4) return PillIndicatorTypes.RED;
    if (val > 4 && val <= 7) return PillIndicatorTypes.AMBER;
    return PillIndicatorTypes.GREEN;
  },
  ImpactLevelToPill: (val: number): PillTypes => {
    if (val < 0) return PillTypes.INDICATOR_ACCENT_0;
    if (val > 0 && val <= 4) return PillTypes.INDICATOR_ACCENT_1;
    if (val > 4 && val <= 7) return PillTypes.INDICATOR_ACCENT_2;
    return PillTypes.INDICATOR_ACCENT_3;
  },
  ImpactLevelShort: (val: ImpactLevel | number) => {
    let s = ["", "L", "L", "L", "L", "M", "M", "M", "H", "H", "H", "H"];
    return s[val] || "-";
  },
  ImpactLevelMin: (val: ImpactLevel | number) => {
    let s = ["Unknown", "Low", "Medium", "High"];
    return s[val];
  },
  ImpactLevelFromFullRange: (val: ImpactLevel | number) => {
    if (val > 0 && val <= 4) return ImpactLevel.LOW;
    if (val > 4 && val <= 7) return ImpactLevel.MEDIUM;
    if (val > 7) return ImpactLevel.HIGH;
    return ImpactLevel.UNKNOWN;
  },
  StakeholderFeelings: (val: ImpactLevel | number) => {
    let s = [
      I18n.t("phrases.unknown"),
      I18n.t("phrases.concerned"),
      I18n.t("phrases.neutral"),
      I18n.t("phrases.advocate")
    ];
    return s[val];
  },
  InfluenceLevel: (val: ImpactLevel | number) => {
    return influenceLevelArray[val] || "Unknown";
  },
  InfluenceLevelMapped: () => {
    var s = influenceLevelArray.map((e, i) => {
      return {
        key: i === 0 ? "-1" : i + "",
        label: (i === 0 ? "" : i + " - ") + (i === 0 ? "Unknown" : e)
      };
    });
    s.pop();
    return s;
  },
  InfluenceLevelMin: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Medium", "High"];
    return s[val];
  },
  Metric: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  PermissionScope: (val: PermissionScope | number) => {
    switch (val) {
      case PermissionScope.ADMIN:
        return "phrases.admin";
      case PermissionScope.OWNER:
        return "phrases.owner";
      case PermissionScope.CONTRIBUTOR:
        return "phrases.contributor";
      case PermissionScope.NOACCESS:
        return "phrases.noAccess";
      case PermissionScope.READER:
        return "phrases.reader";

      default:
        return "phrases.unknown";
    }
  },
  MetricToEnum: (val: number) => {
    if (val < 5 && val >= 1) {
      return ImpactLevel.LOW;
    } else if (val >= 5 && val <= 7) {
      return ImpactLevel.MEDIUM;
    } else if (val >= 8) {
      return ImpactLevel.HIGH;
    } else {
      return ImpactLevel.UNKNOWN;
    }
  },
  ProgressStatus: (val: ProgressStatus | number) => {
    let s = ["", "Not Started", "In Progress", "Completed", "Overdue", "On Hold", "Cancelled", "Draft"];
    return s[val];
  },
  ProgrammeProgressStatus: (val: ProgressStatus | number) => {
    let s = ["", "Not Started", "In Progress", "Completed", "Overdue", "On Hold", "Cancelled", "Draft"];
    return s[val];
  },
  ImpactProgressStatus: (val: any) => {
    let s = ["Draft", "Not Started", "In Progress", "Mitigated"];
    return s[val];
  },
  ConcernStatus: (val: ConcernStatus | number) => {
    let s = ["", "Active", "Closed", "No longer relevant"];
    return s[val];
  },
  RagStatus: (val: RagStatus | number) => {
    let s = ["", "Red", "Amber", "Green", "Complete"];
    return s[val];
  },
  ReviewRange: (val: ReviewRange) => {
    switch (val) {
      case ReviewRange.FOUR_TO_SIX_WEEKS:
        return "phrases.reviewRange_FourToSixWeeks";
      case ReviewRange.TWO_TO_FOUR_WEEKS:
        return "phrases.reviewRange_TwoToFourWeeks";
      case ReviewRange.WITHIN_TWO_WEEKS:
        return "phrases.reviewRange_WithinTwoWeeks";
      case ReviewRange.OVERDUE:
        return "phrases.reviewRange_Overdue";

      default:
        return "phrases.unknown";
    }
  }
};

export const getStatusAccentFromImpactLevel = (val: number): any => {
  const level = Translator.MetricToEnum(val);

  const s = ["", "indicator-accent-1", "indicator-accent-2", "indicator-accent-3"];
  return s[level];
};

export enum ConditionFilterKey {
  OR = "or",
  AND = "and"
}

export const Enums = {
  CompanySize,
  StakeholderGroupType,
  StakeholderType,
  RagStatus,
  MilestoneType,
  RagStatusString,
  ProgressStatus,
  ImpactType,
  ImpactLevel,
  ImpactLevelBulkUpdate,
  MitigationConfidence,
  AttitudeLevel,
  ConcernStatus,
  LifecycleStatus,
  AppEventType,
  ErrorCode,
  LogLevel,
  UiSizes,
  PermissionScope,
  LocalCookies,
  ChangeType,
  ChangeEventType,
  Translator,
  ImpactCompletionState,
  SettingType,
  ReviewRange,
  TableBadgeTypes,
  ImpactLevelDropdownValues,
  HeatmapMonths,
  ActionField,
  Months,
  HeatmapFilters,
  ConditionFilterKey
};

export const defaultDateTimeString = "1900-01-01T00:00:00";
export const minimumDate = "2010-01-01T00:00:00";
