import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";
import { Animations } from "../../../../../core/util/Animations";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ProjectPhasesModel } from "./ProjectPhases_model";
import { IF } from "../../../../../components/hoc/If";
import { PhasesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/PhasesGrid/PhasesGridView_view";
import moment from "moment";

export interface ProjectPhaseSettingsProps {}

const ProjectPhaseSettings: React.FunctionComponent<ProjectPhaseSettingsProps> = observer(() => {
  const authUser = useFlightPathUser();
  const { organisationId, projectId } = useParams<{ organisationId: string; projectId: string }>();
  const [model] = useState(() => new ProjectPhasesModel(+organisationId, +projectId, authUser));

  useEffect(() => {
    model.onMount();
  }, [model]);
  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const { projectPhases, project } = model;

  return (
    <div
      className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4 phases-settings insight-tab-content__space-filler`}
      id="EditProjectPhasesPage"
      style={{ height: "100%" }}
    >
      <IF condition={model.isLoading}>
        <PositionedSpinner></PositionedSpinner>
      </IF>
      <IF condition={!model.isLoading}>
        <div className="row px-4">
          <h1>
            {project?.name} | {moment(project?.startDate).format("L")} - {moment(project?.actualEndDate).format("L")}
          </h1>
        </div>
        <PhasesGrid
          data={projectPhases}
          onFieldUpdate={model.loadProjectPhases}
          refreshDataHandler={model.loadProjectPhases}
        />
      </IF>
    </div>
  );
});

export { ProjectPhaseSettings };
