import { useParams } from "react-router-dom";
import { InsightInnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useCanManagePermissions } from "../../../../contexts/permissions/PermissionHooks";

export const ProjectSettingsWrapper: React.FC = () => {
  const { projectId } = useParams();
  const organisationId = useCurrentOrganisationId();

  const canManagePermissions = useCanManagePermissions(organisationId, +projectId);
  const items = [
    {
      label: I18n.t("entities.projects"),
      url: Pages.projects.settings.index.generateLink(organisationId, +projectId)
    },
    {
      label: I18n.t("entities.milestones"),
      url: Pages.projects.settings.milestones.generateLink(organisationId, +projectId)
    },
    {
      label: I18n.t("entities.benefits"),
      url: Pages.projects.settings.benefits.generateLink(organisationId, +projectId)
    },
    {
      label: I18n.t("entities.phases"),
      url: Pages.projects.settings.phases.generateLink(organisationId, +projectId)
    }
  ];
  if (canManagePermissions) {
    items.push({
      label: I18n.t("entities.permissions"),
      url: Pages.projects.settings.permissions.generateLink(organisationId, +projectId)
    });
  }
  return <InsightInnerNavContent title={I18n.t("phrases.settings")} items={items} />;
};
